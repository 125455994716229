"use client";
import { QueryClientProvider } from "@/queries/provider";
import { ReactNode } from "react";

type Props = {
  children: ReactNode;
};

export function Providers({ children }: Props) {
  return <QueryClientProvider>{children}</QueryClientProvider>;
}
