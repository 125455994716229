import {
  QueryClient,
  QueryClientProvider as QueryClientProviderBase,
} from "@tanstack/react-query";
import { ReactNode } from "react";

const queryClient = new QueryClient();

type Props = {
  children: ReactNode;
};

export const QueryClientProvider = ({ children }: Props) => (
  <QueryClientProviderBase client={queryClient}>
    {children}
  </QueryClientProviderBase>
);
